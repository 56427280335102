export default {
  containerMaxWidth: 1080,
  colors: {
    brand: 'rgb(251,194,60)',
    brandSecondary: 'rgb(255,181,0)',
    success: '#5DE744',
    danger: '#B70000',
    dark : '#343A40',
    darkest : '#111',
    light : '#f3f3f3',
    lightest : '#fff',
    link: '#0f89d4',
  },
  bodyFontFamily: 'Open Sans, sans-serif',
  headingFontFamily: '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
  dropCapsFontFamily: 'Itim',
  bodyLineHeight:'150%',
  fontSize: '100%',
  mobileBreakpoint : 762,
}